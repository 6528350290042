import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, newForm) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("form", JSON.stringify(newForm));

        const res = await axios.post(Env.apiPath + "updateForm", toSend);
        return res.data.error;
    } catch (err) {
        return err;
    }
}