export default {
    en: {
        failForm: "Form Submit Failed",
        successForm: "Form Submit Successfully",
        infoFormBad: "Please check your form carefully before submit"
    },
    zh: {
        failForm: "资料提交失败",
        successForm: "资料提交成功",
        infoFormBad: "请仔细检查资料后再提交"
    }
}