<template>
<van-dialog v-model:show="show" :title="title" show-cancel-button @confirm="confirm()" :confirm-button-color="'#ee0a24'" :confirm-button-text="lang[langCode].confirm" :cancel-button-text="lang[langCode].cancel" :confirm-button-disabled="disable" class="md:w-1/3 w-full">
    <van-divider />
    <div class="overflow-y-scroll my-max-h px-4 pb-8 gap-4 flex flex-col">
        <div v-for="(line, index) in lines" :key="index">
            {{line}}
        </div>
        <div class="py-4">
            <van-checkbox v-model="checked" shape="square" @change="changeConfirm">I acknowledge that I have read, understood, and agreed to the terms and conditions of this agreement</van-checkbox>
        </div>
    </div>
</van-dialog>
</template>

<script>
import lang from "@/langs/PayFeeOfflineLang.js";
import {
    ref
} from "vue";
import {
    Dialog,
    Divider,
    Checkbox
} from "vant";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Divider.name]: Divider,
        [Checkbox.name]: Checkbox
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const show = ref(false);
        const lines = ref([]);
        const disable = ref(true);
        const title = ref("");

        const showDialog = function (ls) {
            show.value = true;
            lines.value = JSON.parse(JSON.stringify(ls));
            if (lines.value.length > 1) {
                title.value = lines.value.shift();
            }
        };

        const confirm = function () {
            emit("userConfirmed");
        };

        const changeConfirm = function (c) {
            disable.value = !c;
        };

        return {
            lang,
            show,
            showDialog,
            lines,
            confirm,
            checked: ref(false),
            disable,
            changeConfirm,
            title
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
    scrollbar-width: none;
}

.my-max-h::-webkit-scrollbar {
    width: 0px;
}

.my-max-h::-webkit-scrollbar-track {
    display: none;
}
</style>
