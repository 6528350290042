<template>
<van-cell-group class="shadow-md" inset>
    <van-field v-for="(item, index) in form" :key="index" :label="item[langCode]" v-model="item.in" placeholder="请输入" :is-link="item.options.length > 0" @click="showItemOptions(item)" :disabled="item.id">
        <template #input v-if="item.options.length">
            <span>{{item.in}}</span>
        </template>
    </van-field>
</van-cell-group>
<van-action-sheet v-model:show="showOption" :actions="options" @select="clickAction" />
</template>

<script>
import lang from "@/langs/StudentFormLang.js";
import {
    ref
} from "vue";
import {
    CellGroup,
    Field,
    ActionSheet,
    Notify
} from "vant";
import UpdateForm from "@/asyncs/UpdateStudentForm.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [ActionSheet.name]: ActionSheet
    },
    props: {
        langCode: {
            type: String,
            default: "zh"
        },
    },
    setup(props) {
        const form = ref([]);

        const clearForm = function () {
            form.value.splice(0, form.value.length);
        };
        const setForm = function (classForm, extra) {
            clearForm();
            classForm.forEach(function (item) {
                if (item.id === "dob") {
                    item.in = extra[0];
                }
                form.value.push(item);
            });
        }
        const hasForm = function () {
            if (form.value.length) {
                return true;
            } else {
                return false;
            }
        };

        const showOption = ref(false);
        const options = ref([]);
        let clickedItem = null;
        const showItemOptions = function (item) {
            if (!item.options.length) {
                return;
            }
            options.value.splice(0, options.value.length);
            item.options.forEach(function (option) {
                options.value.push({
                    name: option
                });
            });
            showOption.value = true;
            clickedItem = item;
        };

        const clickAction = function (a) {
            if (clickedItem) {
                clickedItem.in = a.name;
            }
            showOption.value = false;
        };

        const getFormToSend = function () {
            const formToSend = [];
            let index = 0;
            while (index < form.value.length) {
                const formItem = form.value[index];
                if (!formItem.in && formItem.must) {
                    return [];
                }
                formToSend.push({
                    zh: formItem.zh,
                    en: formItem.en,
                    in: formItem.in
                })
                index += 1;
            }
            return formToSend;
        };

        const sendForm = async function (classId) {
            const formToSend = getFormToSend();
            if (!formToSend.length) {
                Notify({
                    type: "danger",
                    message: lang[props.langCode].infoFormBad
                });
                return;
            }

            const error = await UpdateForm(classId, formToSend);
            if (error) {
                Notify({
                    type: "danger",
                    message: lang[props.langCode].failForm
                });
            } else {
                Notify({
                    type: "success",
                    message: lang[props.langCode].successForm
                });
            }
        };

        const fillForm = function (classForm, studentForm) {
            clearForm();
            classForm.forEach(function (item, index) {
                item.in = studentForm[index].in;
                form.value.push(item);
            });
        };

        return {
            form,
            setForm,
            hasForm,
            clearForm,
            showItemOptions,
            showOption,
            options,
            clickAction,
            sendForm,
            fillForm,
            getFormToSend
        };
    }
};
</script>
