import axios from "axios";
import CheckHttp from "@/asyncs/MyHttpCheck.js";
import {
    Notify
} from "vant";
import Env from "@/logics/Envs.js";

export default async function (classId, studentId) {
    try {
        const params = { classId, studentId };
        const res = await axios.get(Env.apiPath + "loadStudentFromClass", { params });
        if (res.data.error) {
            Notify({
                type: "danger",
                message: res.data.error,
                duration: 5000
            });
            return null;
        }
        return res.data.student;
    } catch (err) {
        CheckHttp(err);
        return null;
    }
}