export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        you: "You",
        apply: "Applied",
        notAplly: "Not applied",
        admit: "Admitted!",
        notAdmit: "Waiting admission",
        buttonQuit: "Cancel Application",
        buttonApply: "Apply Class",
        buttonPay: "Pay Fees",
        confirmApplyTitle: "Apply Class",
        confirmApplyMessage: "Are you sure to sign up the class of [@@]?",
        failNotStart: "Sorry, this class is not yet open to apply",
        failCredits: "Credits not enough",
        failApply: "Failed to apply: ",
        confirmQuitTitle: "Quit Class",
        confirmQuitMessage: "Are you sure to quit the class of [@@]?",
        successApply: "Successfully applied: ",
        successQuit: "Class Dropped",
        buttonSendForm: "Submit Form",
        infoForm: "Please fill in the registration form",
        infoFormBad: "Please check your form carefully before submit"
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        you: "您",
        apply: "已报名",
        notAplly: "未报名",
        admit: "已确认录取",
        notAdmit: "等待录取",
        buttonQuit: "取消报名",
        buttonApply: "报名课程",
        buttonPay: "支付费用",
        confirmApplyTitle: "报名课程",
        confirmApplyMessage: "确定要报名 [@@] 课程吗？",
        failNotStart: "抱歉，此课程还未开启报名",
        failCredits: "抱歉，您的 Credits 不够，无法报名",
        failApply: "报名失败: ",
        confirmQuitTitle: "退出课程",
        confirmQuitMessage: "确定要退出 [@@] 课程吗？",
        successApply: "报名成功: ",
        successQuit: "已退出课程",
        buttonSendForm: "提交报名资料",
        infoForm: "报名前请先填写资料报名表",
        infoFormBad: "请仔细检查资料后再提交"
    }
}