<template>
    <div class="pt-32 flex flex-wrap" v-if="classDetails">
        <div class="md:w-1/2 w-full mb-12">
            <class-details-card :class-details="classDetails" :lang-code="langCode" :my-theme="myTheme" />
        </div>
        <div class="md:w-1/2 w-full flex flex-col items-center">
            <div class="w-full flex flex-col mb-8" v-if="isStudent && active">
                <van-cell-group class="shadow-md" inset>
                    <van-cell center>
                        <template #title>
                            <div class="flex flex-col items-start">
                                <div class="flex flex-col items-center">
                                    <van-image v-if="user" :src="Env.userPath + user.profile.icon" round fit="cover"
                                        width="6rem" height="6rem" />
                                    <span class="mt-2 mb-2 text-lg">{{ lang[langCode].you }}</span>
                                </div>
                            </div>
                        </template>
                        <template #value>
                            <div class="flex flex-wrap justify-start items-baseline">
                                <van-tag class="mr-3 mb-2" :color="TagTypes.personal.color" size="medium"
                                    v-for="(tag, index) in user.meta.tags.personal" :key="index">{{ tag }}</van-tag>
                                <div class="w-full h-0"></div>
                                <van-tag class="mr-3 mb-2" v-if="enrolled" size="medium" type="success">{{
        lang[langCode].apply }}</van-tag>
                                <van-tag class="mr-3 mb-2" v-if="!enrolled" size="medium" type="warning">{{
        lang[langCode].notAplly }}</van-tag>
                                <van-tag class="mr-3 mb-2" v-if="enrolled && admitted" size="medium" type="success">{{
        lang[langCode].admit }}</van-tag>
                                <van-tag class="mr-3 mb-2" v-if="enrolled && !admitted" size="medium" type="warning">{{
        lang[langCode].notAdmit }}</van-tag>
                            </div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
            <div class="w-full mb-8">
                <student-form ref="studentForm" :lang-code="langCode" />
            </div>
            <div class="px-4 w-full pb-32 flex flex-col gap-y-3" v-if="isStudent && active">
                <van-button v-if="!enrolled" type="success" round block size="large" @click="enrollClass()">{{
        lang[langCode].buttonApply }}</van-button>
                <van-button v-if="enrolled && studentForm.hasForm()" type="primary" round block size="large"
                    @click="sendForm()">{{
        lang[langCode].buttonSendForm }}</van-button>
                <van-button v-if="enrolled && !admitted" type="danger" round block size="large" @click="quitClass()">{{
        lang[langCode].buttonQuit }}</van-button>
                <van-button v-if="enrolled && shouldPay" type="primary" round block size="large"
                    @click="showActionSheet()">{{
        lang[langCode].buttonPay }}</van-button>
            </div>
            <div class="px-4 w-full pb-32 flex flex-col gap-y-3" v-if="isManager">
                <van-button type="warning" block size="large" @click="goManageStudent()">管理学生</van-button>
            </div>
            <div class="w-full flex flex-col mb-8">
                <van-cell-group class="mb-8 shadow-md" v-for="(teacher, index) in teachers" :key="index" inset>
                    <profile-cell :user="teacher" :lang-code="langCode" :short-bio="true" />
                </van-cell-group>
            </div>
        </div>
        <pay-action-sheet ref="actionsRef" v-if="isStudent" @payment-success="paymentSuccess()" :lang-code="langCode" />
        <declare-confirm ref="declare" @user-confirmed="sendEnrollment()" />
    </div>
</template>

<script>
import lang from "@/langs/PageClassDetailsLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    CellGroup,
    Cell,
    Image as VanImage,
    Tag,
    Button,
    Dialog,
    ActionSheet,
    Field,
    Notify
} from "vant";
import Env from "@/logics/Envs.js";
import myTheme from "@/logics/MyVantTheme.js";
import UserTypes from "@/static_values/StaticUserTypes.js";
import TagTypes from "@/static_values/StaticTagTypes.js";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import ProfileCell from "@/components/ProfileCell.vue";
import PayActionSheet from "@/components/dialog/PayActionSheet.vue";
import StudentForm from "@/components/StudentForm.vue";
import DeclareConfirm from "@/components/dialog/ClassDeclareConfirm.vue";
import GetSession from "@/asyncs/GetSession.js";
import LoadClass from "@/asyncs/LoadClass.js";
import LoadStudent from "@/asyncs/LoadStudent.js";
import ViewUser from "@/asyncs/ViewUser.js";
import EnrollClass from "@/asyncs/EnrollClass.js";
import CheckTransaction from "@/asyncs/CheckTransaction.js";
import QuitClass from "@/asyncs/QuitClass.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [VanImage.name]: VanImage,
        [Tag.name]: Tag,
        [Button.name]: Button,
        [ActionSheet.name]: ActionSheet,
        [Field.name]: Field,
        ClassDetailsCard,
        ProfileCell,
        PayActionSheet,
        StudentForm,
        DeclareConfirm
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        const route = useRoute();
        const router = useRouter();
        const isStudent = ref(false);
        const active = ref(false);
        const classDetails = ref(null);
        const teachers = ref([]);
        const enrolled = ref(false);
        const admitted = ref(false);
        const shouldPay = ref(false);
        const studentForm = ref(null);
        const declare = ref(null);

        const setupData = async function () {
            isStudent.value = UserTypes.student.value === store.state.user.userType;

            if (isStudent.value) {
                const student = await LoadStudent(classDetails.value.classId, store.state.user.userId);
                if (student) {
                    if (classDetails.value.payPlans.length) {
                        const paid = await CheckTransaction(classDetails.value.classId);
                        if (!paid[0]) {
                            shouldPay.value = true;
                        }
                    }
                    enrolled.value = true;
                    if (student.valid) {
                        admitted.value = true;
                    }
                    if (student.form) {
                        studentForm.value.fillForm(classDetails.value.form, student.form);
                    }
                } else {
                    enrolled.value = false;
                }
            }

            let index = 0;
            while (index < classDetails.value.teachers.length) {
                const teacher = await ViewUser(classDetails.value.teachers[index].id);
                teachers.value.push(teacher);
                index += 1;
            }
        };

        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                active.value = store.state.user.active;
                classDetails.value = await LoadClass(route.params.classId);
                if (!classDetails.value) {
                    return;
                }
                store.commit("setPageTitle", classDetails.value.className);
                await setupData();
            });
        });

        const actionsRef = ref(null);
        const showActionSheet = function () {
            actionsRef.value.showActions(classDetails.value, store.state.user.userId);
        };

        const enrollClass = async function () {
            if (classDetails.value.form && !studentForm.value.hasForm()) {
                studentForm.value.setForm(classDetails.value.form, [store.state.user.profile.dob]);
                Notify({
                    type: "primary",
                    message: lang[store.state.lang].infoForm
                });
                return;
            }
            if (!classDetails.value.join) {
                Notify({
                    type: "danger",
                    message: lang[store.state.lang].failNotStart
                });
                return;
            }
            if (classDetails.value.classDates.cost > store.state.user.credit) {
                Notify({
                    type: "danger",
                    message: lang[store.state.lang].failCredits
                });
                return;
            }
            if (classDetails.value.declare) {
                declare.value.showDialog(classDetails.value.declare);
            } else {
                try {
                    await Dialog.confirm({
                        title: lang[store.state.lang].confirmApplyTitle,
                        message: lang[store.state.lang].confirmApplyMessage.replace("@@", classDetails.value.className),
                        confirmButtonColor: "#ee0a24",
                        confirmButtonText: lang[store.state.lang].confirm,
                        cancelButtonText: lang[store.state.lang].cancel
                    });
                } catch (e) {
                    console.log(e);
                    return;
                }
                sendEnrollment();
            }
        };

        const sendEnrollment = async function () {
            const formToSend = studentForm.value.getFormToSend();
            if (classDetails.value.form) {
                if (!formToSend.length) {
                    Notify({
                        type: "danger",
                        message: lang[store.state.lang].infoFormBad
                    });
                    return;
                }
            }

            const ok = await EnrollClass(classDetails.value.classId, formToSend);
            if (!ok) {
                Notify({
                    type: "danger",
                    message: lang[store.state.lang].failApply
                });
            } else {
                Notify({
                    type: "success",
                    message: lang[store.state.lang].successApply + classDetails.value.className
                });
                if (classDetails.value.classDates.cost > 0) {
                    store.state.user.credit -= classDetails.value.classDates.cost;
                }
                router.push("/viewClassList");
            }
        };

        const quitClass = async function () {
            try {
                await Dialog.confirm({
                    title: lang[store.state.lang].confirmQuitTitle,
                    message: lang[store.state.lang].confirmQuitMessage.replace("@@", classDetails.value.className),
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[store.state.lang].confirm,
                    cancelButtonText: lang[store.state.lang].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const ok = await QuitClass(classDetails.value.classId, classDetails.value.className,
                store.state.user.userId, store.state.user.profile.showName,
                store.state.user.userId);
            if (ok) {
                Notify({
                    type: "success",
                    message: lang[store.state.lang].successQuit
                });
                enrolled.value = false;
                if (classDetails.value.classDates.cost > 0) {
                    store.state.user.credit += classDetails.value.classDates.cost;
                }
                studentForm.value.clearForm();
                router.push("/viewClassList");
            }
        };

        const paymentSuccess = function () {
            shouldPay.value = false;
        };

        const sendForm = function () {
            studentForm.value.sendForm(classDetails.value.classId);
        };

        const goManageStudent = function () {
            router.push("/classStudents/" + classDetails.value.classId);
        };

        return {
            lang,
            langCode: computed(() => store.state.lang),
            Env,
            myTheme,
            TagTypes,
            classDetails,
            enrolled,
            admitted,
            teachers,
            actionsRef,
            showActionSheet,
            user: computed(() => store.state.user),
            isStudent,
            active,
            enrollClass,
            shouldPay,
            quitClass,
            paymentSuccess,
            sendForm,
            studentForm,
            declare,
            sendEnrollment,
            isManager: computed(() => UserTypes.manager.value === store.state.user.userType),
            goManageStudent
        };
    }
}
</script>
